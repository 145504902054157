import "tippy.js/dist/tippy.css";
import { renderDate } from "../../formatting/dateFormat";
import { hourlyOrDailyDateDifference } from "../../formatting/dateDifferHoursDaily";
import {
  BUYBOX_CONDITION_IMAGE,
  BUYBOX_SELLER_IMAGE,
  BUYBOX_SHIP_SPEED_IMAGE,
} from "../../formatting/buyboxInfo";
import BigNumber from "bignumber.js";
import { formatToPoundsOunces } from "../../formatting/weightFormat";

const cloudfrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

export const masterColumnSet = [
  {
    data: "product.yom_sku",
    name: "product.yom_sku",
    type: "hidden",
    visible: false,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "UPC(Universal Product Code)/ SKU (Stock Keeping Unit)" position = "bottom" trigger = "click"><p>UPC</p></Tooltip>',
    name: "product.upc",
    data: "product.upc",
    title:
      '<Tooltip title = "UPC(Universal Product Code)/ SKU (Stock Keeping Unit)" position = "bottom" trigger = "click"><p>UPC</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "ASIN (Amazon Standard Identification Number)" position = "bottom" trigger = "click"><p>ASIN</p></Tooltip>',
    name: "product.asin",
    data: "product.asin",
    title:
      '<Tooltip title = "ASIN (Amazon Standard Identification Number)" position = "bottom" trigger = "click"><p>ASIN</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "UPC/SKU that relates the selling unit to the source unit where we get the product from" position = "bottom" trigger = "click"><p>Equiv</p></Tooltip>',
    name: "product.equiv",
    data: "product.equiv",
    title:
      '<Tooltip title = "UPC/SKU that relates the selling unit to the source unit where we get the product from" position = "bottom" trigger = "click"><p>Equiv</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Yom \'s title for item (not Amazon \'s)" position = "bottom" trigger = "click"><p>Title</p></Tooltip>',
    name: "product.title",
    data: "product.title",
    title:
      '<Tooltip title = "Yom \'s title for item (not Amazon \'s)" position = "bottom" trigger = "click"><p>Title</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Amazon\'s title for item (not Yom \'s)" position = "bottom" trigger = "click"><p>Amazon Title</p></Tooltip>',
    name: "product.amazon_title",
    data: "product.amazon_title",
    title:
      '<Tooltip title = "Amazon\'s title for item (not Yom \'s)" position = "bottom" trigger = "click"><p>Amazon Title</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
  },
  {
    label:
      '<Tooltip title = "Items condition" position = "bottom" trigger = "click"><p>Cond</p></Tooltip>',
    name: "product.cond",
    data: "product.cond",
    title:
      '<Tooltip title = "Items condition" position = "bottom" trigger = "click"><p>Cond</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Weight of the product in lbs and ounces" position = "bottom" trigger = "click"><p>Weight</p></Tooltip>',
    name: "product.packaged_weight_ounces",
    data: "product.packaged_weight_ounces",
    title:
      '<Tooltip title = "Weight of the product in lbs and ounces" position = "bottom" trigger = "click"><p>Weight</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    excludeFromGeneralSearch: true,
    render: function (data, type, row) {
      return formatToPoundsOunces(data);
    },
  },
  {
    label:
      '<Tooltip title = "How many components are in this product" position = "bottom" trigger = "click"><p>Component Qty</p></Tooltip>',
    name: "product.component_count",
    data: "product.component_count",
    title:
      '<Tooltip title = "How many components are in this product" position = "bottom" trigger = "click"><p>Component Qty</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Custom user inputted amount to adjust base handling cost" position = "bottom" trigger = "click"><p>Handling Cost Adjustment</p></Tooltip>',
    name: "product.yom_handling_cost_adjustment",
    data: "product.yom_handling_cost_adjustment",
    title:
      '<Tooltip title = "Custom user inputted amount to adjust base handling cost" position = "bottom" trigger = "click"><p>Handling Cost Adjustment</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Genre" position = "bottom" trigger = "click"><p>Genre</p></Tooltip>',
    name: "product.genre",
    data: "product.genre",
    title:
      '<Tooltip title = "Genre" position = "bottom" trigger = "click"><p>Genre</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Discontinued Date from alliance file" position = "bottom" trigger = "click"><p>Discontinued</p></Tooltip>',
    name: "product.discontinued_date",
    data: "product.discontinued_date",
    title:
      '<Tooltip title = "Discontinued Date from alliance file" position = "bottom" trigger = "click"><p>Discontinued</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "media_types[].id",
    label:
      '<Tooltip title = "The media types in this product (Vinyl, Book, CD, DVD, Bluray, 4K)" position = "bottom" trigger = "click"><p>Media Types</p></Tooltip>',
    data: "media_types[].id",
    searchable: false,
    orderable: false,
    excludeFromGeneralSearch: true,
    type: "select",
    multiple: true,
    visible: false, // using view column below for display
  },
  {
    name: "product_media_types_view.media_types",
    title:
      '<Tooltip title = "The media types in this product (Vinyl, Book, CD, DVD, Bluray, 4K)" position = "bottom" trigger = "click"><p>Media Types</p></Tooltip>',
    data: "product_media_types_view.media_types",
    type: "hidden", // using mjoin field above for edit form
  },
  {
    name: "product.alliance_sku",
    label:
      '<Tooltip title = "Alliance Entertainment Product ID" position = "bottom" trigger = "click"><p>Alliance SKU</p></Tooltip>',
    data: "product.alliance_sku",
    title:
      '<Tooltip title = "Alliance Entertainment Product ID" position = "bottom" trigger = "click"><p>Alliance SKU</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Studio who owns the rights to the title" position = "bottom" trigger = "click"><p>Studio</p></Tooltip>',
    name: "product.studio",
    data: "product.studio",
    title:
      '<Tooltip title = "Studio who owns the rights to the title" position = "bottom" trigger = "click"><p>Studio</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Inventory Updates - For communcAn analyst can request a warehouser to verifty inventory & a warehouser can let the analyst know when inventory for a product has been updated" position = "bottom" trigger = "click"><p>Inventory Updates</p></Tooltip>',
    name: "product.rstat",
    data: "product.rstat",
    title:
      '<Tooltip title = "Inventory Updates - For communcAn analyst can request a warehouser to verifty inventory & a warehouser can let the analyst know when inventory for a product has been updated" position = "bottom" trigger = "click"><p>Inventory Updates</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Whether or not the product has been retired." position = "bottom" trigger = "click"><p>Retired</p></Tooltip>',
    name: "product.retired",
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.retired",
    title:
      '<Tooltip title = "Whether or not the product has been retired." position = "bottom" trigger = "click"><p>Retired</p></Tooltip>',
    render: function (data, type, row) {
      if (data === 0) return "No";
      if (!data) return null;
      return "Yes";
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "When the product was retired." position = "bottom" trigger = "click"><p>Retired Timestamp</p></Tooltip>',
    name: "product.retired_timestamp",
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.retired_timestamp",
    title:
      '<Tooltip title = "When the product was retired." position = "bottom" trigger = "click"><p>Retired Timestamp</p></Tooltip>',
    render: renderDate,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Title release date or the Amazon listing creation date" position = "bottom" trigger = "click"><p>Release Date</p></Tooltip>',
    name: "product.release_date",
    data: "product.release_date",
    title:
      '<Tooltip title = "Title release date or the Amazon listing creation date" position = "bottom" trigger = "click"><p>Release Date</p></Tooltip>',
    render: renderDate,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Weight and Dimensions of a collection" position = "bottom" trigger = "click"><p>Ship Wt</p></Tooltip>',
    name: "product.ship_weight",
    data: "product.ship_weight",
    title:
      '<Tooltip title = "Weight and Dimensions of a collection" position = "bottom" trigger = "click"><p>Ship Wt</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Identifier for parents of a collection (.amx / .rmx / .mx / .bmx) " position = "bottom" trigger = "click"><p>Part #</p></Tooltip>',
    name: "product.part_number",
    data: "product.part_number",
    title:
      '<Tooltip title = "Identifier for parents of a collection (.amx / .rmx / .mx / .bmx) " position = "bottom" trigger = "click"><p>Part #</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Ebay product ID " position = "bottom" trigger = "click"><p>ItemID</p></Tooltip>',
    name: "product.itemid",
    data: "product.itemid",
    title:
      '<Tooltip title = "Ebay product ID " position = "bottom" trigger = "click"><p>ItemID</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "The artcard number for this collection" position = "bottom" trigger = "click"><p>Card#</p></Tooltip>',
    name: "product.osid",
    data: "product.osid",
    title:
      '<Tooltip title = "The artcard number for this collection" position = "bottom" trigger = "click"><p>Card#</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Rarewaves Product ID" position = "bottom" trigger = "click"><p>FVid</p></Tooltip>',
    name: "product.fvid",
    data: "product.fvid",
    title:
      '<Tooltip title = "Rarewaves Product ID" position = "bottom" trigger = "click"><p>FVid</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Warner Brothers Product ID" position = "bottom" trigger = "click"><p>WBSKU</p></Tooltip>',
    name: "product.wbsku",
    data: "product.wbsku",
    title:
      '<Tooltip title = "Warner Brothers Product ID" position = "bottom" trigger = "click"><p>WBSKU</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "CASIN (Amazon Canada Standard Identification Number) ASIN for Amazon Canada" position = "bottom" trigger = "click"><p>CASIN</p></Tooltip>',
    name: "product.casin",
    data: "product.casin",
    title:
      '<Tooltip title = "CASIN (Amazon Canada Standard Identification Number) ASIN for Amazon Canada" position = "bottom" trigger = "click"><p>CASIN</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Walmart SKU" position = "bottom" trigger = "click"><p>WalSKU</p></Tooltip>',
    name: "product.walsku",
    data: "product.walsku",
    title:
      '<Tooltip title = "Walmart SKU" position = "bottom" trigger = "click"><p>WalSKU</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Price paid for purchasing item physically in store not online" position = "bottom" trigger = "click"><p>Phys</p></Tooltip>',
    name: "product.phys",
    data: "product.phys",
    title:
      '<Tooltip title = "Price paid for purchasing item physically in store not online" position = "bottom" trigger = "click"><p>Phys</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Quality Analyst Notes position = "bottom" trigger = "click"><p>QAN</p></Tooltip>',
    name: "product.quality_analyst_notes",
    data: "product.quality_analyst_notes",
    title:
      '<Tooltip title = "Quality Analyst Notes" position = "bottom" trigger = "click"><p>QAN</p></Tooltip>',
  },
  {
    name: "product.total_sales",
    label:
      '<Tooltip title =  "The total units of this UPC sold on Amazon over the product\'s lifetime." position =  "bottom " trigger =  "click "><p>Total UPC Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.total_sales",
    title:
      '<Tooltip title =  "The total units of this UPC sold on Amazon over the product\'s lifetime." position =  "bottom " trigger =  "click "><p>Total UPC Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product.90_day_sales",
    label:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 90 days." position =  "bottom " trigger =  "click "><p>90 Day UPC Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.90_day_sales",
    title:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 90 days." position =  "bottom " trigger =  "click "><p>90 Day UPC Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product.30_day_sales",
    label:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 30 days." position =  "bottom " trigger =  "click "><p>30 Day UPC Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.30_day_sales",
    title:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 30 days." position =  "bottom " trigger =  "click "><p>30 Day UPC Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product.7_day_sales",
    label:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 7 days." position =  "bottom " trigger =  "click "><p>7 Day UPC Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.7_day_sales",
    title:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 7 days." position =  "bottom " trigger =  "click "><p>7 Day UPC Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Quantity of product available to ship" position = "bottom" trigger = "click"><p>ATS</p></Tooltip>',
    name: "product.ats",
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.ats",
    title:
      '<Tooltip title = "Quantity of product available to ship" position = "bottom" trigger = "click"><p>ATS</p></Tooltip>',
    render: function (data, type, row) {
      if (data === 999999) return "Misthreading error.";
      if (data === 999998) return "Inventory error.";
      return data;
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Timestamp for when the ATS value last increased" position = "bottom" trigger = "click"><p>ATS Increased</p></Tooltip>',
    name: "product.ats_last_increase_timestamp",
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.ats_last_increase_timestamp",
    title:
      '<Tooltip title = "Timestamp for when the ATS value last increased" position = "bottom" trigger = "click"><p>ATS Increased</p></Tooltip>',
    render: renderDate,
  },
  {
    label:
      '<Tooltip title = "Master Analyst Inventory. Current inventory on hand + Inventory On Order (ONO) for all UPCs required to fulfill this row." position = "bottom" trigger = "click"><p>MAI</p></Tooltip>',
    name: "product.master_analyst_inv",
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.master_analyst_inv",
    title:
      '<Tooltip title = "Master Analyst Inventory. Current inventory on hand + Inventory On Order (ONO) for all UPCs required to fulfill this row." position = "bottom" trigger = "click"><p>MAI</p></Tooltip>',
    render: function (data, type, row) {
      if (data === 999999) return "Misthreading error.";
      if (data === 999998) return "Inventory error.";
      return data;
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "The availability of supply inventory needed to fulfill this selling option from our distributors. IS=In stock, BO=Back Order, OP=Out of Print" position = "bottom" trigger = "click"><p>Restock</p></Tooltip>',
    name: "product.restock_status_calculated",
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.restock_status_calculated",
    title:
      '<Tooltip title = "The availability of supply inventory needed to fulfill this selling option from our distributors. IS=In stock, BO=Back Order, OP=Out of Print" position = "bottom" trigger = "click"><p>Restock</p></Tooltip>',
    render: function (data, type, row) {
      if (data === "999999") return "Misthreading error.";
      if (data === "OP")
        return `<span style="color:#CC0000;font-weight:700;font-size:17px;">OP</span>`;
      if (data === "BO")
        return `<span style="color:#0066FF;font-weight:700;font-size:17px;">BO</span>`;
      if (data === "IS")
        return `<span style="color:#339933;font-weight:700;font-size:17px;">IS</span>`;

      return null;
    },
  },
  {
    name: "product.weeks_until_depleted",
    label:
      '<Tooltip title = "Weeks to Depletion. How many weeks of stock do we have on hand or purchased to fulfill this selling option, based on the last 30 days of total page sales?" position = "bottom" trigger = "click"><p>WTD</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.weeks_until_depleted",
    title:
      '<Tooltip title = "Weeks to Depletion. How many weeks of stock do we have on hand or purchased to fulfill this selling option, based on the last 30 days of total page sales?" position = "bottom" trigger = "click"><p>WTD</p></Tooltip>',
    render: function (data, type, row) {
      if (data === 9999999.9) return "No sales.";
      if (data === 9999999.8) return "Inventory error.";
      return data;
    },
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_families_view.part_number",
    label:
      '<Tooltip title = "The part number this product has across families." position = "bottom" trigger = "click"><p>#</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_families_view.part_number",
    title:
      '<Tooltip title = "The part number this product has across families." position = "bottom" trigger = "click"><p>#</p></Tooltip>',
  },
  {
    name: "product_families_view.families",
    label:
      '<Tooltip title = "Product families this row is a member of." position = "bottom" trigger = "click"><p>Product Families</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_families_view.families",
    title:
      '<Tooltip title = "Product families this row is a member of." position = "bottom" trigger = "click"><p>Product Families</p></Tooltip>',
    render: function (data, type, row) {
      if (!data) return null;
      if (data.length < 78) return data;
      return `${data.substring(0, 75)}...`;
    },
  },
  {
    name: "product.amazon_stock_weeks_until_depleted",
    label:
      '<Tooltip title = "How many weeks of stock do we have in Amazon’s network to fulfill this selling option, based on the last 30 days of total page sales?" position = "bottom" trigger = "click"><p>WTD AF</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.amazon_stock_weeks_until_depleted",
    title:
      '<Tooltip title = "How many weeks of stock do we have in Amazon’s network to fulfill this selling option, based on the last 30 days of total page sales?" position = "bottom" trigger = "click"><p>WTD AF</p></Tooltip>',
    render: function (data, type, row) {
      if (data === 9999999.9) return "No sales.";
      if (data === 9999999.8) return "Inventory error.";
      return data;
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Master replacement cost of goods - purchasable. The lowest possible cost of purchasing all of the supply inventory required to fulfill this selling option, from any suppliers." position = "bottom" trigger = "click"><p>MRCOG-P</p></Tooltip>',
    name: "product.replacement_cost_cents",
    type: "readonly",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title = "Master replacement cost of goods - purchasable. The lowest possible cost of purchasing all of the supply inventory required to fulfill this selling option, from any suppliers." position = "bottom" trigger = "click"><p>MRCOG-P</p></Tooltip>',
    data: "product.replacement_cost_cents",
    render: function (data, type, row) {
      if (!data) return null;
      if (data === 999999) return "Misthreading error.";
      return (data / 100).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "The lowest cost of purchasing all of the supply inventory required to fulfill this selling option, which is in stock with a distributor supplier." position = "bottom" trigger = "click"><p>MRCOG-IS</p></Tooltip>',
    name: "product.replacement_cost_dist_cents",
    type: "readonly",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title = "The lowest cost of purchasing all of the supply inventory required to fulfill this selling option, which is in stock with a distributor supplier." position = "bottom" trigger = "click"><p>MRCOG-IS</p></Tooltip>',
    data: "product.replacement_cost_dist_cents",
    render: function (data, type, row) {
      if (!data) return null;
      if (data === 999999) return "Misthreading error.";
      return (data / 100).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "The sum of the average cost of all in-stock components needed to fulfill this collection." position = "bottom" trigger = "click"><p>MAVCG</p></Tooltip>',
    name: "product.master_avg_cost_of_goods_cents",
    type: "readonly",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title = "The sum of the average cost of all in-stock components needed to fulfill this collection." position = "bottom" trigger = "click"><p>MAVCG</p></Tooltip>',
    data: "product.master_avg_cost_of_goods_cents",
    render: function (data, type, row) {
      if (!data) return null;
      if (data === 999999) return "Misthreading error.";
      return (data / 100).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Master On Order. Current inventory On Order (ONO) for all UPCs required to fulfill this row." position = "bottom" trigger = "click"><p>MONO</p></Tooltip>',
    name: "product.master_quantity_on_order",
    type: "readonly",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title = "Master On Order. Current inventory On Order (ONO) for all UPCs required to fulfill this row." position = "bottom" trigger = "click"><p>MONO</p></Tooltip>',
    data: "product.master_quantity_on_order",
    render: function (data, type, row) {
      if (data === 999999) return "Misthreading error.";
      if (data === 999998) return "Inventory error.";
      return data;
    },
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.merchant_available",
    label:
      '<Tooltip title =  "Is there a merchant listing available for the ASIN of this row?" position =  "bottom " trigger =  "click "><p>Assoc</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.merchant_available",
    title:
      '<Tooltip title =  "Is there a merchant listing available for the ASIN of this row?" position =  "bottom " trigger =  "click "><p>Assoc</p></Tooltip>',
    render: function (data, type, row) {
      if (data === 0) return "No";
      if (!data) return null;
      return "Yes";
    },
  },
  {
    name: "product_listings.purchasable_stock",
    label:
      '<Tooltip title =  "The total purchasable units for the ASIN\'s listing page." position =  "bottom " trigger =  "click "><p>Page Stock</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.purchasable_stock",
    title:
      '<Tooltip title =  "The total purchasable units for the ASIN\'s listing page." position =  "bottom " trigger =  "click "><p>Page Stock</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.30_day_profit",
    label:
      '<Tooltip title =  "The total profit of this ASIN’s last 30 day sales." position =  "bottom " trigger =  "click "><p>30 Day Profit</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.30_day_profit",
    title:
      '<Tooltip title =  "The total profit of this ASIN’s last 30 day sales." position =  "bottom " trigger =  "click "><p>30 Day Profit</p></Tooltip>',
    render: function (data, type, row) {
      if (!data) return null;

      const numericUsd = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(data);

      return numericUsd;
    },
  },
  {
    name: "product.page_sales_365_90_30_7",
    label:
      '<Tooltip title =  "Page sales in the following format: 365 - 90 - 30 - 7." position =  "bottom " trigger =  "click "><p>All Page Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.page_sales_365_90_30_7",
    title:
      '<Tooltip title =  "Page sales in the following format: 365 - 90 - 30 - 7." position =  "bottom " trigger =  "click "><p>All Page Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.total_sales",
    label:
      '<Tooltip title =  "The lifetime aggregate sales for YOM\'s products on this ASIN listing page." position =  "bottom " trigger =  "click "><p>Total Page Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.total_sales",
    title:
      '<Tooltip title =  "The lifetime aggregate sales for YOM\'s products on this ASIN listing page." position =  "bottom " trigger =  "click "><p>Total Page Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.365_day_sales",
    label:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 365 days." position =  "bottom " trigger =  "click "><p>365 Day Page Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.365_day_sales",
    title:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 365 days." position =  "bottom " trigger =  "click "><p>365 Day Page Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.90_day_sales",
    label:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 90 days." position =  "bottom " trigger =  "click "><p>90 Day Page Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.90_day_sales",
    title:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 90 days." position =  "bottom " trigger =  "click "><p>90 Day Page Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.30_day_sales",
    label:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 30 days." position =  "bottom " trigger =  "click "><p>30 Day Page Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.30_day_sales",
    title:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 30 days." position =  "bottom " trigger =  "click "><p>30 Day Page Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.7_day_sales",
    label:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 7 days." position =  "bottom " trigger =  "click "><p>7 Day Page Sales</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.7_day_sales",
    title:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 7 days." position =  "bottom " trigger =  "click "><p>7 Day Page Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.open_date",
    label:
      '<Tooltip title =  "Date that this listing was created" position =  "bottom " trigger =  "click "><p>Listing Created Date</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.open_date",
    title:
      '<Tooltip title =  "Date that this listing was created" position =  "bottom " trigger =  "click "><p>Listing Created Date</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product.sales_rank",
    label:
      '<Tooltip title =  "Sales rank on Amazon\'s website" position =  "bottom " trigger =  "click "><p>Sales Rank</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.sales_rank",
    title:
      '<Tooltip title =  "Sales rank on Amazon\'s website" position =  "bottom " trigger =  "click "><p>Sales Rank</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product.product_category",
    label:
      '<Tooltip title =  "" position =  "bottom " trigger =  "click "><p>Product Category</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.product_category",
    title:
      '<Tooltip title =  "" position =  "bottom " trigger =  "click "><p>Product Category</p></Tooltip>',
  },
  {
    name: "product.buy_box_price",
    label:
      '<Tooltip title =  "The price that is currently listed under the buy box" position =  "bottom " trigger =  "click "><p>BuyBox</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.buy_box_price",
    title:
      '<Tooltip title =  "The price that is currently listed under the buy box" position =  "bottom " trigger =  "click "><p>BuyBox</p></Tooltip>',
    render: function (data, type, row) {
      if (!data) return null;

      const numericUsd = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(data);

      return numericUsd;
    },
    excludeFromGeneralSearch: true,
  },
  {
    name: "product.buy_box_info",
    label:
      "<div title='Information about the buybox seller, ship time, and various other listing conditions.'>Buy Box Info</div>",
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product.buy_box_info",
    title: "<div id='buybox-info-container'>Buy Box Info</div>",
    render: function (data, type, row) {
      if (!data) return null;

      const updatedAt = renderDate(row.product.buy_box_updated_timestamp);

      data = data.toLowerCase();

      if (!data.includes("-") || data.includes("ineligible")) {
        return `<img src=${BUYBOX_CONDITION_IMAGE[data]} alt='${data}' title='${data}\n\nUpdated: ${updatedAt}' />`;
      }

      if (data.includes("amazon")) {
        let [seller, shipSpeed] = data.split(" - ");

        if (seller === "temp out of stock") {
          [seller, shipSpeed] = [shipSpeed, seller]; // reversing order for TEMP OUT OF STOCK display
          return `<div><img src=${BUYBOX_CONDITION_IMAGE[shipSpeed]} alt='${shipSpeed}' title='${shipSpeed}\n\nUpdated: ${updatedAt}' /><img src=${BUYBOX_SELLER_IMAGE[seller]} alt='${seller}' title='${seller}\n\nUpdated: ${updatedAt}' /></div>`;
        }

        if (shipSpeed)
          return `<div><img src=${BUYBOX_SELLER_IMAGE[seller]} alt='${seller}' title='${seller}\n\nUpdated: ${updatedAt}' /><img src=${BUYBOX_SHIP_SPEED_IMAGE[shipSpeed]} alt='${shipSpeed}' title='${shipSpeed}\n\nUpdated: ${updatedAt}' /></div>`;

        return `<img src=${BUYBOX_SELLER_IMAGE[seller]} alt='${seller}' title='${seller}\n\nUpdated: ${updatedAt}' />`;
      }

      const [status, seller, shipSpeed] = data.split(" - ");

      let statusImg = BUYBOX_CONDITION_IMAGE[status];
      let shipSpeedImg = BUYBOX_SHIP_SPEED_IMAGE[shipSpeed];

      if (seller === "not yom") {
        if (shipSpeed)
          return `<div><img src=${statusImg} alt='${status}' title='${status} - not yom\n\nUpdated: ${updatedAt}' /><img src=${shipSpeedImg} alt='${shipSpeed}' title='${shipSpeed}\n\nUpdated: ${updatedAt}' /></div>`;

        return `<img src=${statusImg} alt='${status}' title='${status} - not yom\n\nUpdated: ${updatedAt}' />`;
      }

      let sellerImg = BUYBOX_SELLER_IMAGE[seller];

      if (shipSpeed)
        return `<div><img src=${statusImg} alt='${status}' title='${status}\n\nUpdated: ${updatedAt}' /><img src=${sellerImg} alt='${seller}' title='${seller}\n\nUpdated: ${updatedAt}' /><img src=${shipSpeedImg} alt='${shipSpeed}' title='${shipSpeed}\n\nUpdated: ${updatedAt}' /></div>`;

      return `<div><img src=${statusImg} alt='${status}' title='${status}\n\nUpdated: ${updatedAt}' /><img src=${sellerImg} alt='${seller}' title='${seller}\n\nUpdated: ${updatedAt}' /></div>`;
    },
  },
  {
    name: "product.buy_box_updated_timestamp",
    data: "product.buy_box_updated_timestamp",
    type: "hidden",
    visible: false,
  },
  {
    type: "readonly",
    attr: {
      disabled: true,
    },
    title: "<div id='buybox-markup-container'>Buybox Markup</div>",
    label:
      '<Tooltip title = "Percentage markup/markdown relative to children/equivalent products" position = "bottom" trigger = "click"><p>Buybox Markup</p></Tooltip>',

    name: "product.buy_box_markup_display",
    data: "product.buy_box_markup_display",
    render: function (data, type, row) {
      const error = row.product.buy_box_markup_error;
      const missingPieces = row.product.buy_box_markup_missing_pieces;

      if (error === "Misthread") {
        return "Misthread";
      }

      if (error === "Ineligible") {
        return '<b><font size="8"> &#8734;</font></b>';
      }

      if (error === "Not applicable") {
        return '<span style="color:Purple"><b><font size="5">N/A</font></b></span>';
      }

      if (missingPieces) {
        return `<div style='display:flex;align-items:center;gap:7px;'><img src="https://i.ibb.co/Nsdhbrz/1.png" height="30px" alt="image" /> <b style='font-size:20px;'>${missingPieces}</b></div>`;
      }

      if (data === null) return null;

      const percentage = data.match(/[0-9]+/);

      if (data.includes("Premium")) {
        return `<div style='display:flex;align-items:center;gap:7px;'><b>+${percentage[0]}%</b><img src="https://i.ibb.co/m9Dj2FY/Green-Arrow.png" style='color:green;'></img></div>`;
      }

      if (data.includes("Discount")) {
        return `<div style='display:flex;align-items:center;gap:7px;'><b>${percentage[0]}%</b><img src="https://i.ibb.co/pXbvMqQ/Red-Arrow.png" style='color:green;'></img></div>`;
      }

      if (data.includes("PAR")) {
        return "<b>PAR</div>";
      }

      return data;
    },
  },
  {
    name: "supplier_pricing.walmart_updated_timestamp",
    data: "supplier_pricing.walmart_updated_timestamp",
    type: "hidden",
    visible: false,
  },
  {
    name: "product.fba_latest_shipment_quantity_timestamp",
    data: "product.fba_latest_shipment_quantity_timestamp",
    type: "hidden",
    visible: false,
  },
  {
    name: "product.fba_latest_shipment_quantity",
    data: "product.fba_latest_shipment_quantity",
    label:
      '<Tooltip title = "Quantity last shipped to Amazon FBA facility. Displays timestamp on hover" position = "bottom" trigger = "click"><p>FBA Last Shipment</p></Tooltip>',
    title:
      '<Tooltip title = "Quantity last shipped to Amazon FBA facility. Displays timestamp on hover" position = "bottom" trigger = "click"><p>FBA Last Shipment</p></Tooltip>',
    render: function (data, type, row) {
      if (!data) return null;

      const updatedTimestamp = renderDate(
        row.product.fba_latest_shipment_quantity_timestamp
      );

      return `<span title='Updated: ${updatedTimestamp}'>${data}</span>`;
    },
  },
  {
    name: "collection.yom_sku",
    data: "collection.yom_sku",
    type: "hidden",
    visible: false,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Archived IDs of Ebay collections" position = "bottom" trigger = "click"><p>Ebay</p></Tooltip>',
    name: "collection.ebay_member_of_archive",
    data: "collection.ebay_member_of_archive",
    title:
      '<Tooltip title = "Archived IDs of Ebay collections" position = "bottom" trigger = "click"><p>Ebay</p></Tooltip>',
    render: function (data, type, row) {
      if (!data) return null;
      if (data.length < 78) return data;
      return `${data.substring(0, 75)}...`;
    },
  },

  {
    label:
      '<Tooltip title = "Date the last time a directive was built for the product" position = "bottom" trigger = "click"><p>DBD</p></Tooltip>',
    name: "product.dbd",
    data: "product.dbd",
    title:
      '<Tooltip title = "Date the last time a directive was built for the product" position = "bottom" trigger = "click"><p>DBD</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Supplier hot links to selected products, or to suppliers not currently being scraped" position = "bottom" trigger = "click"><p>Sup Link</p></Tooltip>',
    name: "product.sup_lin",
    data: "product.sup_lin",
    title:
      '<Tooltip title = "Supplier hot links to selected products, or to suppliers not currently being scraped" position = "bottom" trigger = "click"><p>Sup Link</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "WN (Warehouse notes)" position = "bottom" trigger = "click"><p>WN</p></Tooltip>',
    name: "product.wn",
    data: "product.wn",
    title:
      '<Tooltip title = "WN (Warehouse notes)" position = "bottom" trigger = "click"><p>WN</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "WWO (Warehouse work orders)" position = "bottom" trigger = "click"><p>WWO</p></Tooltip>',
    name: "product.wwo",
    data: "product.wwo",
    title:
      '<Tooltip title = "WWO (Warehouse work orders)" position = "bottom" trigger = "click"><p>WWO</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Significant events in the life of a product listing" position = "bottom" trigger = "click"><p>Listory</p></Tooltip>',
    name: "product.listory",
    data: "product.listory",
    title:
      '<Tooltip title = "Significant events in the life of a product listing" position = "bottom" trigger = "click"><p>Listory</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Listing department cue, and work orders" position = "bottom" trigger = "click"><p>Listed</p></Tooltip>',
    name: "product.listed",
    data: "product.listed",
    title:
      '<Tooltip title = "Listing department cue, and work orders" position = "bottom" trigger = "click"><p>Listed</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "Pick Status" position =  "bottom " trigger =  "click "><p>Pick Status</p></Tooltip>',
    name: "product.pick_status",
    data: "product.pick_status",
    title:
      '<Tooltip title =  "Pick Status" position =  "bottom " trigger =  "click "><p>Pick Status</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "Repricer pricing rule " position =  "bottom " trigger =  "click "><p>Rule</p></Tooltip>',
    name: "product.rule",
    data: "product.rule",
    title:
      '<Tooltip title =  "Repricer pricing rule " position =  "bottom " trigger =  "click "><p>Rule</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "ONO (On Order) Inventory that has been ordered through supplier, and has not yet been delivered to our warehouse" position =  "bottom " trigger =  "click "><p>ONO</p></Tooltip>',
    name: "product.ono",
    data: "product.ono",
    type: "readonly",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title =  "ONO (On Order) Inventory that has been ordered through supplier, and has not yet been delivered to our warehouse" position =  "bottom " trigger =  "click "><p>ONO</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "The average (mean) cost paid for the product in our warehouse." position =  "bottom " trigger =  "click "><p>AVCG</p></Tooltip>',
    name: "product.avcg",
    data: "product.avcg",
    title:
      '<Tooltip title =  "The average (mean) cost paid for the product in our warehouse." position =  "bottom " trigger =  "click "><p>AVCG</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "The master average cost of goods paid for the product using the AVCG-H 90 value." position =  "bottom " trigger =  "click "><p>MAVCG-H 90</p></Tooltip>',
    name: "product.master_avg_cogs_ninety_day",
    data: "product.master_avg_cogs_ninety_day",
    title:
      '<Tooltip title =  "The master average cost of goods paid for the product using the AVCG-H 90 value." position =  "bottom " trigger =  "click "><p>MAVCG-H 90</p></Tooltip>',
    render: function (data, type) {
      if (data === 9999.99) return "Misthreading error.";
      if (data === 9999.98) return "Inventory error.";
      return data;
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "The average (mean) cost paid for the product in our warehouse in the last 90 days." position =  "bottom " trigger =  "click "><p>AVCG-H 90</p></Tooltip>',
    name: "product.last_ninety_day_avg_cogs",
    data: "product.last_ninety_day_avg_cogs",
    title:
      '<Tooltip title =  "The average (mean) cost paid for the product in our warehouse in the last 90 days." position =  "bottom " trigger =  "click "><p>AVCG-H 90</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "The lowest cost paid for the product in our warehouse in the last year." position =  "bottom " trigger =  "click "><p>One Year Lowest COGS</p></Tooltip>',
    name: "product.one_year_lowest_cost",
    data: "product.one_year_lowest_cost",
    title:
      '<Tooltip title =  "The lowest cost paid for the product in our warehouse in the last year." position =  "bottom " trigger =  "click "><p>One Year Lowest COGS</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "AI (Analyst Inventory) Current Inventory On Hand  + On Order Inventory (ONO) " position =  "bottom " trigger =  "click "><p>AI</p></Tooltip>',
    name: "product.ai",
    data: "product.ai",
    title:
      '<Tooltip title =  "AI (Analyst Inventory) Current Inventory On Hand  + On Order Inventory (ONO) " position =  "bottom " trigger =  "click "><p>AI</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Next Shipment - number of units to be shipped" position =  "bottom " trigger =  "click "><p>Next Ship Qty</p></Tooltip>',
    name: "product.next_amz_ship_qty",
    data: "product.next_amz_ship_qty",
    title:
      '<Tooltip title =  "Next Shipment - number of units to be shipped" position =  "bottom " trigger =  "click "><p>Next Ship Qty</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Yom\'s new ship stock target value used in YSR holiday calc" position =  "bottom " trigger =  "click "><p>YSR NSS Rec</p></Tooltip>',
    name: "product.ysr_nss_rec",
    data: "product.ysr_nss_rec",
    type: "readonly",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title =  "Yom\'s new ship stock target value used in YSR holiday calc" position =  "bottom " trigger =  "click "><p>YSR NSS Rec</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Yom\'s purchase recommendation" position =  "bottom " trigger =  "click "><p>YPR</p></Tooltip>',
    name: "product.yom_purchase_rec",
    data: "product.yom_purchase_rec",
    type: "readonly",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title =  "Yom\'s purchase recommendation" position =  "bottom " trigger =  "click "><p>YPR</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Yom\'s recommended ship quantity scaled for holidays" position =  "bottom " trigger =  "click "><p>YSR Holiday</p></Tooltip>',
    name: "product.yom_ship_rec_holiday",
    data: "product.yom_ship_rec_holiday",
    type: "readonly",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title =  "Yom\'s recommended ship quantity scaled for holidays" position =  "bottom " trigger =  "click "><p>YSR Holiday</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Yom\'s recommended ship quantity" position =  "bottom " trigger =  "click "><p>YSR</p></Tooltip>',
    name: "product.yom_ship_rec",
    data: "product.yom_ship_rec",
    type: "readonly",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title =  "Yom\'s recommended ship quantity" position =  "bottom " trigger =  "click "><p>YSR</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Amazon\'s recommended ship quantity" position =  "bottom " trigger =  "click "><p>ASR</p></Tooltip>',
    name: "product.amz_recommended_ship_quantity",
    data: "product.amz_recommended_ship_quantity",
    title:
      '<Tooltip title =  "Amazon\'s recommended ship quantity" position =  "bottom " trigger =  "click "><p>ASR</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "ASQ (Amazon Ship Quantity) Number of units to be shipped to Amazon\'s warehouse network from our warehouse " position =  "bottom " trigger =  "click "><p>Amz Ship Qty</p></Tooltip>',
    name: "product.amz_ship_qty",
    data: "product.amz_ship_qty",
    title:
      '<Tooltip title =  "ASQ (Amazon Ship Quantity) Number of units to be shipped to Amazon\'s warehouse network from our warehouse " position =  "bottom " trigger =  "click "><p>Amz Ship Qty</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Difference between YOM ship recommendation and AMZ ship recommendation" position =  "bottom " trigger =  "click "><p>YSR ASR Diff</p></Tooltip>',
    name: "product.yom_ship_rec_amz_ship_rec_diff",
    data: "product.yom_ship_rec_amz_ship_rec_diff",
    type: "readonly",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title =  "Difference between YOM ship recommendation and AMZ ship recommendation" position =  "bottom " trigger =  "click "><p>YSR ASR Diff</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Projected profit from selling YOM ship recommended quantity" position =  "bottom " trigger =  "click "><p>YSR Potential Profit</p></Tooltip>',
    name: "product_profit_projection_view.yom_ship_rec_potential_profit",
    data: "product_profit_projection_view.yom_ship_rec_potential_profit",
    title:
      '<Tooltip title =  "Projected profit from selling YOM ship recommended quantity" position =  "bottom " trigger =  "click "><p>YSR Potential Profit</p></Tooltip>',
    excludeFromGeneralSearch: true,
    type: "readonly",
    attr: {
      disabled: true,
    },
    render: function (data, type) {
      if (!data) return null;

      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(data);
    },
  },
  {
    label:
      '<Tooltip title =  "Total days with restock status = IS in the past 30 days. Reported as # days (%)" position =  "bottom " trigger =  "click "><p>30 Day IS Rate</p></Tooltip>',
    name: "product.30_day_restock_in_stock_count",
    data: "product.30_day_restock_in_stock_count",
    title:
      '<Tooltip title =  "Total days with restock status = IS in the past 30 days. Reported as # days (%)" position =  "bottom " trigger =  "click "><p>30 Day IS Rate</p></Tooltip>',
    excludeFromGeneralSearch: true,
    type: "readonly",
    attr: {
      disabled: true,
    },
    render: function (data, type) {
      if (data === undefined || data === null) return null;

      const percentage = new BigNumber(data).dividedBy(30).multipliedBy(100);
      return `${data} (${percentage.toFixed(0)}%)`;
    },
  },
  {
    label:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 1</p></Tooltip>',
    name: "product.analytics_db_column_1",
    data: "product.analytics_db_column_1",
    title:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 1</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 2</p></Tooltip>',
    name: "product.analytics_db_column_2",
    data: "product.analytics_db_column_2",
    title:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 2</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 3</p></Tooltip>',
    name: "product.analytics_db_column_3",
    data: "product.analytics_db_column_3",
    title:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 3</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 4</p></Tooltip>',
    name: "product.analytics_db_column_4",
    data: "product.analytics_db_column_4",
    title:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 4</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "ASD (Amazon Ship Date) Date units must be shipped to Amazon\'s warehouse network from our warehouse" position =  "bottom " trigger =  "click "><p>Amazon Queue</p></Tooltip>',
    name: "product.amz_queue",
    data: "product.amz_queue",
    title:
      '<Tooltip title =  "ASD (Amazon Ship Date) Date units must be shipped to Amazon\'s warehouse network from our warehouse" position =  "bottom " trigger =  "click "><p>Amazon Queue</p></Tooltip>',
    render: function (data, type) {
      if (data) {
        return type === "display" && data.length > 30
          ? data.substr(0, 30) + "…"
          : data;
      } else {
        return "";
      }
    },
  },
  {
    label:
      '<Tooltip title =  "Analyst/Purchaser work order " position =  "bottom " trigger =  "click "><p>House</p></Tooltip>',
    name: "product.house",
    data: "product.house",
    title:
      '<Tooltip title =  "Analyst/Purchaser work order " position =  "bottom " trigger =  "click "><p>House</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "Marking column (General Use) " position =  "bottom " trigger =  "click "><p>Orders</p></Tooltip>',
    name: "product.orders",
    data: "product.orders",
    title:
      '<Tooltip title =  "Marking column (General Use) " position =  "bottom " trigger =  "click "><p>Orders</p></Tooltip>',
    render: function (data, type) {
      if (data) {
        return type === "display" && data.length > 30
          ? data.substr(0, 30) + "…"
          : data;
      } else {
        return "";
      }
    },
  },
  {
    label:
      '<Tooltip title =  "BCP (Barcode Printed) Whether or not the barcode has been printed for this product (ex. Yes, No)" position =  "bottom " trigger =  "click "><p>BCP</p></Tooltip>',
    name: "product.bcp",
    data: "product.bcp",
    title:
      '<Tooltip title =  "BCP (Barcode Printed) Whether or not the barcode has been printed for this product (ex. Yes, No)" position =  "bottom " trigger =  "click "><p>BCP</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "AN (Analyst Notes) Notes for the analyst to use" position =  "bottom " trigger =  "click "><p>AN</p></Tooltip>',
    name: "product.an",
    data: "product.an",
    title:
      '<Tooltip title =  "AN (Analyst Notes) Notes for the analyst to use" position =  "bottom " trigger =  "click "><p>AN</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "When the product was last scanned into inventory " position =  "bottom " trigger =  "click "><p>Add Date</p></Tooltip>',
    name: "product.add_date",
    data: "product.add_date",
    title:
      '<Tooltip title =  "When the product was last scanned into inventory " position =  "bottom " trigger =  "click "><p>Add Date</p></Tooltip>',
    render: renderDate,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "When the product was last scanned out of inventory " position =  "bottom " trigger =  "click "><p>Sub Date</p></Tooltip>',
    name: "product.sub_date",
    data: "product.sub_date",
    title:
      '<Tooltip title =  "When the product was last scanned out of inventory " position =  "bottom " trigger =  "click "><p>Sub Date</p></Tooltip>',
    render: renderDate,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Where the item can be found in our warehouse " position =  "bottom " trigger =  "click "><p>Shelf</p></Tooltip>',
    name: "product.shelf",
    data: "product.shelf",
    title:
      '<Tooltip title =  "Where the item can be found in our warehouse " position =  "bottom " trigger =  "click "><p>Shelf</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "How much of that product is in our East Aurora warehouse " position =  "bottom " trigger =  "click "><p>Inv</p></Tooltip>',
    name: "product.inv",
    data: "product.inv",
    type: "readonly",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title =  "How much of that product is in our East Aurora warehouse " position =  "bottom " trigger =  "click "><p>Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Alliance Entertainment Distributor inventory " position =  "bottom " trigger =  "click "><p>Dist Inv</p></Tooltip>',
    name: "product.dist_inv",
    data: "product.dist_inv",
    title:
      '<Tooltip title =  "Alliance Entertainment Distributor inventory " position =  "bottom " trigger =  "click "><p>Dist Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Dealsareus Distributor inventory (DIsplayed as #currently on hand - #currently available from their supplier) " position =  "bottom " trigger =  "click "><p>DAU Inv</p></Tooltip>',
    name: "product.dau_inv",
    data: "product.dau_inv",
    title:
      '<Tooltip title =  "Dealsareus Distributor inventory (DIsplayed as #currently on hand - #currently available from their supplier) " position =  "bottom " trigger =  "click "><p>DAU Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },

  {
    label:
      '<Tooltip title =  "Mila inventory available" position =  "bottom " trigger =  "click "><p>Mila Inv</p></Tooltip>',
    name: "product.mila_inv",
    data: "product.mila_inv",
    title:
      '<Tooltip title =  "Mila inventory available" position =  "bottom " trigger =  "click "><p>Mila Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Mila inv\n\nUpdated at: ${renderDate(
        row["product"]["mila_data_timestamp"]
      )}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title =  "Discount Entertainment inventory available" position =  "bottom " trigger =  "click "><p>DE Inv</p></Tooltip>',
    name: "product.discount_entertainment_inv",
    data: "product.discount_entertainment_inv",
    title:
      '<Tooltip title =  "Discount Entertainment inventory available" position =  "bottom " trigger =  "click "><p>DE Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Discount Entertainment inv\n\nUpdated at: ${renderDate(
        row["product"]["discount_entertainment_data_timestamp"]
      )}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title = "Gruv supplier inventory available" position =  "bottom " trigger =  "click "><p>Gruv Inv</p></Tooltip>',
    name: "product.gruv_inv",
    data: "product.gruv_inv",
    title:
      '<Tooltip title = "Gruv supplier inventory available" position =  "bottom " trigger =  "click "><p>Gruv Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Gruv inv\n\nUpdated at: ${renderDate(
        row["product"]["gruv_data_timestamp"]
      )}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title =  "Wildcard 1 supplier inventory available" position =  "bottom " trigger =  "click "><p>WC1 Inv</p></Tooltip>',
    name: "product.wildcard1_inv",
    data: "product.wildcard1_inv",
    title:
      '<Tooltip title =  "Wildcard 1 supplier inventory available" position =  "bottom " trigger =  "click "><p>WC1 Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Wildcard1 inv\n\nUpdated at: ${renderDate(
        row["product"]["wildcard1_data_timestamp"]
      )}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title =  "Wildcard 2 supplier inventory available" position =  "bottom " trigger =  "click "><p>WC2 Inv</p></Tooltip>',
    name: "product.wildcard2_inv",
    data: "product.wildcard2_inv",
    title:
      '<Tooltip title =  "Wildcard 2 supplier inventory available" position =  "bottom " trigger =  "click "><p>WC2 Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Wildcard2 inv\n\nUpdated at: ${renderDate(
        row["product"]["wildcard2_data_timestamp"]
      )}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title =  "The quantity of damage products for each damage code (1-3) in ascending order i.e; 1-2-3." position =  "bottom " trigger =  "click "><p>DMG Inv</p></Tooltip>',
    name: "product.dmg_inv",
    data: "product.dmg_inv",
    title:
      '<Tooltip title =  "The quantity of damage products for each damage code (1-3) in ascending order i.e; 1-2-3." position =  "bottom " trigger =  "click "><p>DMG Inv</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "alpha1 " position =  "bottom " trigger =  "click "><p>alpha1</p></Tooltip>',
    name: "product.alpha1",
    data: "product.alpha1",
    title:
      '<Tooltip title =  "See Operations Document for current column use" position =  "bottom " trigger =  "click "><p>Alpha 1</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "alpha2 " position =  "bottom " trigger =  "click "><p>alpha2</p></Tooltip>',
    name: "product.alpha2",
    data: "product.alpha2",
    title:
      '<Tooltip title =  "See Operations Document for current column use" position =  "bottom " trigger =  "click "><p>Alpha 2</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "alpha3 " position =  "bottom " trigger =  "click "><p>alpha3</p></Tooltip>',
    name: "product.alpha3",
    data: "product.alpha3",
    title:
      '<Tooltip title =  "See Operations Document for current column use" position =  "bottom " trigger =  "click "><p>Alpha 3</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "num1 " position =  "bottom " trigger =  "click "><p>num1</p></Tooltip>',
    name: "product.num1",
    data: "product.num1",
    title:
      '<Tooltip title =  "See Operations Document for current column use" position =  "bottom " trigger =  "click "><p>Num 1</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "num2 " position =  "bottom " trigger =  "click "><p>num2</p></Tooltip>',
    name: "product.num2",
    data: "product.num2",
    title:
      '<Tooltip title =  "See Operations Document for current column use" position =  "bottom " trigger =  "click "><p>Num 2</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "num3 " position =  "bottom " trigger =  "click "><p>num3</p></Tooltip>',
    name: "product.num3",
    data: "product.num3",
    title:
      '<Tooltip title =  "See Operations Document for current column use" position =  "bottom " trigger =  "click "><p>Num 3</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "prod_list_type " position =  "bottom " trigger =  "click "><p>prod_list_type</p></Tooltip>',
    name: "product.prod_list_type",
    data: "product.prod_list_type",
    title:
      '<Tooltip title =  "Product List Type" position =  "bottom " trigger =  "click "><p>Product List Type</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "Your Online Marketplace\'s current selling price" position =  "bottom " trigger =  "click "><p>Listing Price</p></Tooltip>',
    name: "product.listing_price",
    data: "product.listing_price",
    title:
      '<Tooltip title =  "Your Online Marketplace\'s current selling price" position =  "bottom " trigger =  "click "><p>Listing Price</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "FNSKU (Amazon\'s Fulfillment Network SKU) " position =  "bottom " trigger =  "click "><p>FNSKU</p></Tooltip>',
    name: "product.fnsku",
    data: "product.fnsku",
    title:
      '<Tooltip title =  "FNSKU (Amazon\'s Fulfillment Network SKU) " position =  "bottom " trigger =  "click "><p>FNSKU</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "Purchasable quantity available on Amazon " position =  "bottom " trigger =  "click "><p>Stock</p></Tooltip>',
    name: "product.stock",
    data: "product.stock",
    title:
      '<Tooltip title =  "Purchasable quantity available on Amazon " position =  "bottom " trigger =  "click "><p>Stock</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Your Online Marketplace\'s listing status (F - FBA, M - Merchant, .A - Active, .Inactive, .Incomplete) " position =  "bottom " trigger =  "click "><p>Status</p></Tooltip>',
    name: "product.status",
    data: "product.status",
    title:
      '<Tooltip title =  "Your Online Marketplace\'s listing status (F - FBA, M - Merchant, .A - Active, .Inactive, .Incomplete) " position =  "bottom " trigger =  "click "><p>Status</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "Total number of Amazon sales per SKU" position =  "bottom " trigger =  "click "><p>Total Orders</p></Tooltip>',
    name: "product.total_orders",
    data: "product.total_orders",
    title:
      '<Tooltip title =  "Total number of Amazon sales per SKU" position =  "bottom " trigger =  "click "><p>Total Orders</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Unshipped Orders " position =  "bottom " trigger =  "click "><p>Unshipped Orders</p></Tooltip>',
    name: "product.unshipped_orders",
    data: "product.unshipped_orders",
    title:
      '<Tooltip title =  "Unshipped Orders " position =  "bottom " trigger =  "click "><p>Unshipped Orders</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "How many days since Your Online Marketplace has sold this product " position =  "bottom " trigger =  "click "><p>Time Since Last Order</p></Tooltip>',
    name: "product_listings.last_non_cancelled_order_timestamp",
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "product_listings.last_non_cancelled_order_timestamp",
    title:
      '<Tooltip title =  "How many days since Your Online Marketplace has sold this product " position =  "bottom " trigger =  "click "><p>Time Since Last Order</p></Tooltip>',
    render: function (data, type, row) {
      if (!data) return null;

      const today = new Date();
      const lastestOrderDate = new Date(data * 1000);
      return hourlyOrDailyDateDifference(today, lastestOrderDate);
    },
  },

  {
    label:
      '<Tooltip title =  "Last Ship " position =  "bottom " trigger =  "click "><p>Last Ship</p></Tooltip>',
    name: "product.last_shipment",
    data: "product.last_shipment",
    title:
      '<Tooltip title =  "Last Ship " position =  "bottom " trigger =  "click "><p>Last Ship</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Restock Shipped Stock = Available + FC Transfer + FC Processing + Working + Shipped + Receiving " position =  "bottom " trigger =  "click "><p>New Shipped Stock</p></Tooltip>',
    name: "product.ship_stock",
    data: "product.ship_stock",
    title:
      '<Tooltip title =  "Restock Shipped Stock = Available + FC Transfer + FC Processing + Working + Shipped + Receiving " position =  "bottom " trigger =  "click "><p>New Shipped Stock</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Number of unsold units at Amazon\'s warehouse that have been there for more than 270 days" position =  "bottom " trigger =  "click "><p>Inv Age 271- 365</p></Tooltip>',
    name: "product.inv_age_271_to_365",
    data: "product.inv_age_271_to_365",
    title:
      '<Tooltip title =  "Number of unsold units at Amazon\'s warehouse that have been there for more than 270 days" position =  "bottom " trigger =  "click "><p>Inv Age 271- 365</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "Amazon\'s recommendation for fixing a poor preforming listing " position =  "bottom " trigger =  "click "><p>Alert</p></Tooltip>',
    name: "product.alert",
    data: "product.alert",
    title:
      '<Tooltip title =  "Amazon\'s recommendation for fixing a poor preforming listing " position =  "bottom " trigger =  "click "><p>Alert</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "Units sold and shipped over the past 90 days divided by the average number of units available at fulfillment centers during that time period. A higher number means we sell through inventory fast, and vice versa. " position =  "bottom " trigger =  "click "><p>FBA Sell Thru</p></Tooltip>',
    name: "product.fba_sell_through",
    data: "product.fba_sell_through",
    title:
      '<Tooltip title =  "Units sold and shipped over the past 90 days divided by the average number of units available at fulfillment centers during that time period. A higher number means we sell through inventory fast, and vice versa. " position =  "bottom " trigger =  "click "><p>FBA Sell Thru</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "COGS (Cost of Goods Sold) Price Your Online Marketplace paid for our goods, inputed by a analyst" position = "bottom" trigger = "click"><p>COGS</p></Tooltip>',
    name: "product.cogs",
    data: "product.cogs",
    title:
      '<Tooltip title = "COGS (Cost of Goods Sold) Price Your Online Marketplace paid for our goods, inputed by a analyst" position = "bottom" trigger = "click"><p>COGS</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Shows the timestamp of the last time COGS was updated for a row." position = "bottom" trigger = "click"><p>COGS Timestamp</p></Tooltip>',
    name: "product.cogs_update_timestamp",
    data: "product.cogs_update_timestamp",
    title:
      '<Tooltip title = "Shows the timestamp of the last time COGS was updated for a row." position = "bottom" trigger = "click"><p>COGS Timestamp</p></Tooltip>',
    render: renderDate,
  },
  {
    label:
      '<Tooltip title = "Representation if the product was repriced or prospected using our Amazon pricing calculator" position = "bottom" trigger = "click"><p>Type</p></Tooltip>',
    name: "product.type",
    data: "product.type",
    title:
      '<Tooltip title = "Representation if the product was repriced or prospected using our Amazon pricing calculator" position = "bottom" trigger = "click"><p>Type</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Profit margin percentage" position = "bottom" trigger = "click"><p>Margin</p></Tooltip>',
    name: "product.margin",
    data: "product.margin",
    title:
      '<Tooltip title = "Profit margin percentage" position = "bottom" trigger = "click"><p>Margin</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `${data.toFixed(1)}%`;
      } else {
        return data;
      }
    },
  },
  {
    label:
      '<Tooltip title = "Profit that we get if we were to sell the product (Selling Price - COGS - Amazon fees - Your Online Marketplace Warehouse & Handling fees)" position = "bottom" trigger = "click"><p>UPC Profit</p></Tooltip>',
    name: "product.profit",
    data: "product.profit",
    title:
      '<Tooltip title = "Profit that we get if we were to sell the product (Selling Price - COGS - Amazon fees - Your Online Marketplace Warehouse & Handling fees)" position = "bottom" trigger = "click"><p>UPC Profit</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Selling Price - Amazon fees directly attributable to the sale and fulfillment of an FBA order (inc. referral, fulfillment and closing fees)" position = "bottom" trigger = "click"><p>Proceeds</p></Tooltip>',
    name: "product.proceeds",
    data: "product.proceeds",
    title:
      '<Tooltip title = "Selling Price - Amazon fees directly attributable to the sale and fulfillment of an FBA order (inc. referral, fulfillment and closing fees)" position = "bottom" trigger = "click"><p>Proceeds</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "ROI (Return on Investment) The gain or loss on an investment relative to the amount of money invested (COGS = Warehousing Fee)" position = "bottom" trigger = "click"><p>ROI</p></Tooltip>',
    name: "product.roi",
    data: "product.roi",
    title:
      '<Tooltip title = "ROI (Return on Investment) The gain or loss on an investment relative to the amount of money invested (COGS = Warehousing Fee)" position = "bottom" trigger = "click"><p>ROI</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `${data.toFixed(1)}%`;
      } else {
        return data;
      }
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Selling Price required to achieve a 0% margin" position = "bottom" trigger = "click"><p>List 0</p></Tooltip>',
    name: "product.margin_0_pricing",
    data: "product.margin_0_pricing",
    title:
      '<Tooltip title = "Selling Price required to achieve a 0% margin" position = "bottom" trigger = "click"><p>List 0</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Selling Price required to achieve a 15% margin" position = "bottom" trigger = "click"><p>List 15</p></Tooltip>',
    name: "product.margin_15_pricing",
    data: "product.margin_15_pricing",
    title:
      '<Tooltip title = "Selling Price required to achieve a 15% margin" position = "bottom" trigger = "click"><p>List 15</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Selling Price required to achieve a 30% margin" position = "bottom" trigger = "click"><p>List 30</p></Tooltip>',
    name: "product.margin_30_pricing",
    data: "product.margin_30_pricing",
    title:
      '<Tooltip title = "Selling Price required to achieve a 30% margin" position = "bottom" trigger = "click"><p>List 30</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Keepa 30 day Buybox Avg" position = "bottom" trigger = "click"><p>Keepa 30d</p></Tooltip>',
    name: "product.keepa_buybox_30_day_avg",
    data: "product.keepa_buybox_30_day_avg",
    title:
      '<Tooltip title = "Keepa 30 day buybox average" position = "bottom" trigger = "click"><p>Keepa 30d</p></Tooltip>',
    excludeFromGeneralSearch: true,
    render: (data) => {
      const formatCurrency = (data) =>
        data ? `$${Number(data).toFixed(2)}` : "";
      return formatCurrency(data);
    },
  },
  {
    label:
      '<Tooltip title = "Keepa 30d Sales" position = "bottom" trigger = "click"><p>Keepa 30d Sales</p></Tooltip>',
    name: "product.keepa_30_day_sales_range",
    data: "product.keepa_30_day_sales_range",
    title:
      '<Tooltip title = "Keepa 30 day sales range" position = "bottom" trigger = "click"><p>Keepa 30d Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "YOM market share" position = "bottom" trigger = "click"><p>YOM Market Share</p></Tooltip>',
    name: "product.yom_market_share",
    data: "product.yom_market_share",
    title:
      '<Tooltip title = "YOM Market Share" position = "bottom" trigger = "click"><p>YOM Market Share</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "YOM 30 day average" position = "bottom" trigger = "click"><p>YOM 30d Avg</p></Tooltip>',
    name: "product.yom_buybox_30_day_avg",
    data: "product.yom_buybox_30_day_avg",
    title:
      '<Tooltip title = "YOM 30 day average" position = "bottom" trigger = "click"><p>YOM 30d Avg</p></Tooltip>',
    excludeFromGeneralSearch: true,
    render: (data) => {
      const formatCurrency = (data) =>
        data ? `$${Number(data).toFixed(2)}` : "";
      return formatCurrency(data);
    },
  },
  {
    label:
      '<Tooltip title = "MAVCG - MRCOG-IS" position = "bottom" trigger = "click"><p>MAVCG - MRCOG-IS</p></Tooltip>',
    name: "product.master_avg_replacement_cost_diff",
    data: "product.master_avg_replacement_cost_diff",
    title:
      '<Tooltip title = "MAVCG - MRCOG-IS" position = "bottom" trigger = "click"><p>MAVCG - MRCOG-IS</p></Tooltip>',
    excludeFromGeneralSearch: true,
    render: (data) => {
      const formatCurrency = (data) =>
        data !== null ? `$${Number(data).toFixed(2)}` : "";
      return formatCurrency(data);
    },
  },
  {
    label:
      '<Tooltip title = "When a product was last prospected or priced" position = "bottom" trigger = "click"><p>Calculator TimeStamp</p></Tooltip>',
    name: "product.latest_timestamp",
    data: "product.latest_timestamp",
    title:
      '<Tooltip title = "When a product was last prospected or priced" position = "bottom" trigger = "click"><p>Calculator TimeStamp</p></Tooltip>',
    render: renderDate,
    excludeFromGeneralSearch: true,
  },
  {
    name: "supplier_pricing.yom_sku",
    data: "supplier_pricing.yom_sku",
    type: "hidden",
    visible: false,
    excludeFromGeneralSearch: true,
  },
  {
    columnDependencies: ["supplier_pricing.dtb1_supplier"],
    label:
      '<Tooltip title = "DTB (Direct to Buyer) Terminal or Landed Cost of a product (includes any volume discounts, promotions and rebates)" position = "bottom" trigger = "click"><p>DTB1</p></Tooltip>',
    name: "supplier_pricing.dtb1",
    className: ".dtb",
    data: "supplier_pricing.dtb1",
    title:
      '<Tooltip title = "DTB (Direct to Buyer) Terminal or Landed Cost of a product (includes any volume discounts, promotions and rebates)" position = "bottom" trigger = "click"><p>DTB1</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supplier_pricing.dtb1_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data} `;
    },
  },
  {
    columnDependencies: ["supplier_pricing.dtb2_supplier"],
    label:
      '<Tooltip title = "DTB2 (Supplier with the 2nd lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB2</p></Tooltip>',
    name: "supplier_pricing.dtb2",
    className: ".dtb",
    data: "supplier_pricing.dtb2",
    title:
      '<Tooltip title = "DTB2 (Supplier with the 2nd lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB2</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supplier_pricing.dtb2_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
  },
  {
    columnDependencies: ["supplier_pricing.dtb3_supplier"],
    label:
      '<Tooltip title = "DTB3 (Supplier with the 3rd lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB3</p></Tooltip>',
    name: "supplier_pricing.dtb3",
    className: ".dtb",
    data: "supplier_pricing.dtb3",
    title:
      '<Tooltip title = "DTB3 (Supplier with the 3rd lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB3</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supplier_pricing.dtb3_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
  },
  {
    columnDependencies: ["supplier_pricing.dtb4_supplier"],
    label:
      '<Tooltip title = "DTB4 (Supplier with the 4th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB4</p></Tooltip>',
    name: "supplier_pricing.dtb4",
    className: ".dtb",
    data: "supplier_pricing.dtb4",
    title:
      '<Tooltip title = "DTB4 (Supplier with the 4th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB4</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supplier_pricing.dtb4_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
    excludeFromGeneralSearch: true,
  },
  {
    columnDependencies: ["supplier_pricing.dtb5_supplier"],
    label:
      '<Tooltip title = "DTB5 (Supplier with the 5th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB5</p></Tooltip>',
    name: "supplier_pricing.dtb5",
    className: ".dtb",
    data: "supplier_pricing.dtb5",
    title:
      '<Tooltip title = "DTB5 (Supplier with the 5th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB5</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supplier_pricing.dtb5_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
    excludeFromGeneralSearch: true,
  },
  {
    columnDependencies: ["supplier_pricing.dtb6_supplier"],
    label:
      '<Tooltip title = "DTB6 (Supplier with the 6th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB6</p></Tooltip>',
    name: "supplier_pricing.dtb6",
    className: ".dtb",
    data: "supplier_pricing.dtb6",
    title:
      '<Tooltip title = "DTB6 (Supplier with the 6th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB6</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supplier_pricing.dtb6_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
    excludeFromGeneralSearch: true,
  },
  {
    columnDependencies: ["supplier_pricing.dtb7_supplier"],
    label:
      '<Tooltip title = "DTB7 (Supplier with the 7th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB7</p></Tooltip>',
    name: "supplier_pricing.dtb7",
    className: ".dtb",
    data: "supplier_pricing.dtb7",
    title:
      '<Tooltip title = "DTB7 (Supplier with the 7th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB7</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supplier_pricing.dtb7_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
    excludeFromGeneralSearch: true,
  },
  {
    columnDependencies: ["supplier_pricing.dtb8_supplier"],
    label:
      '<Tooltip title = "DTB8 (Supplier with the 8th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB8</p></Tooltip>',
    name: "supplier_pricing.dtb8",
    className: ".dtb",
    data: "supplier_pricing.dtb8",
    title:
      '<Tooltip title = "DTB8 (Supplier with the 8th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB8</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supplier_pricing.dtb8_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
    excludeFromGeneralSearch: true,
  },
  {
    columnDependencies: ["supplier_pricing.dtb9_supplier"],
    label:
      '<Tooltip title = "DTB9 (Supplier with the 9th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB9</p></Tooltip>',
    name: "supplier_pricing.dtb9",
    className: ".dtb",
    data: "supplier_pricing.dtb9",
    title:
      '<Tooltip title = "DTB9 (Supplier with the 9th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB9</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supplier_pricing.dtb9_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
    excludeFromGeneralSearch: true,
  },
  {
    columnDependencies: ["supplier_pricing.dtb10_supplier"],
    label:
      '<Tooltip title = "DTB10 (Supplier with the 10th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB10</p></Tooltip>',
    name: "supplier_pricing.dtb10",
    className: ".dtb",
    data: "supplier_pricing.dtb10",
    title:
      '<Tooltip title = "DTB10 (Supplier with the 10th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB10</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supplier_pricing.dtb10_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "I (Infinity) Infinity promotion indicator, Infinity out of stock, Infinity heavy" position = "bottom" trigger = "click"><p>i</p></Tooltip>',
    name: "supplier_pricing.i",
    className: ".st",
    data: "supplier_pricing.i",
    title:
      '<Tooltip title = "I (Infinity) Infinity promotion indicator, Infinity out of stock, Infinity heavy" position = "bottom" trigger = "click"><p>i</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Alliance Entertainment price" position = "bottom" trigger = "click"><p>Alliance</p></Tooltip>',
    name: "supplier_pricing.alliance",
    className: ".st",
    data: "supplier_pricing.alliance",
    title: `<img class='small-icon' title = "Alliance Entertainment price" src=${require("../../../assets/icons/alliance.png")} alt="Alliance" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Amazon supplier scraped price" position = "bottom" trigger = "click"><p>Amazon</p></Tooltip>',
    name: "supplier_pricing.amazon",
    className: ".st",
    data: "supplier_pricing.amazon",
    title: `<img class='small-icon' title = "Amazon price" src=${require("../../../assets/icons/amazon.png")} alt="Amazon" />`,

    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Amazon FBA supplier scraped price" position = "bottom" trigger = "click"><p>AmazonFBA</p></Tooltip>',
    name: "supplier_pricing.amazonfba",
    className: ".st",
    data: "supplier_pricing.amazonfba",
    title: `<img class='small-icon' title = "AmazonFBA price" src=${require("../../../assets/icons/amazonfba.png")} alt="AmazonFBA" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Amazon Lowest supplier scraped price" position = "bottom" trigger = "click"><p>AmazonLowest</p></Tooltip>',
    name: "supplier_pricing.amazonlowest",
    className: ".st",
    data: "supplier_pricing.amazonlowest",
    title: `<img class='small-icon' title = "Amazon Low price" src=${require("../../../assets/icons/amazonlowest.png")} alt="Amazon Low" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Amazon Vetted supplier scraped price" position = "bottom" trigger = "click"><p>AmazonVetted</p></Tooltip>',
    name: "supplier_pricing.amazonvetted",
    className: ".st",
    data: "supplier_pricing.amazonvetted",
    title: `<img class='small-icon' title = "Amazon Vetted price" src=${require("../../../assets/icons/amazonvetted.png")} alt="Amazon Vetted" />`,

    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Amazon Canada supplier scraped price" position = "bottom" trigger = "click"><p>AmazonCa</p></Tooltip>',
    name: "supplier_pricing.amazonca",
    className: ".st",
    data: "supplier_pricing.amazonca",
    title: `<img class='small-icon' title = "AmazonCA price" src=${require("../../../assets/icons/amazonca.png")} alt="AmazonCA" />`,

    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Barnes and Noble supplier scraped price" position = "bottom" trigger = "click"><p>Barnes and Noble</p></Tooltip>',
    name: "supplier_pricing.barnesandnoble",
    className: ".st",
    data: "supplier_pricing.barnesandnoble",
    title: `<img class='small-icon' title = "Barnes and Noble price" src=${require("../../../assets/icons/barnesandnoble.png")} alt="Barnes and Noble" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Amazon Canada Lowest supplier scraped price" position = "bottom" trigger = "click"><p>AmazonCALowest</p></Tooltip>',
    name: "supplier_pricing.amazoncalowest",
    className: ".st",
    data: "supplier_pricing.amazoncalowest",
    title: `<img class='small-icon' title = "AmazonCA Low price" src=${require("../../../assets/icons/amazoncalowest.png")} alt="AmazonCA Low" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Amazon Canada Vetted supplier scraped price" position = "bottom" trigger = "click"><p>AmazonCAVetted</p></Tooltip>',
    name: "supplier_pricing.amazoncavetted",
    className: ".st",
    data: "supplier_pricing.amazoncavetted",
    title: `<img class='small-icon' title = "AmazonCA Vetted price" src=${require("../../../assets/icons/amazoncavetted.png")} alt="AmazonCA Vetted" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Best Buy supplier scraped price" position = "bottom" trigger = "click"><p>BestBuy</p></Tooltip>',
    name: "supplier_pricing.bestbuy",
    className: ".st",
    data: "supplier_pricing.bestbuy",
    title: `<img class='small-icon' title = "BestBuy price" src=${require("../../../assets/icons/bestbuy.png")} alt="Bestbuy" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Blowitoutahere supplier scraped price" position = "bottom" trigger = "click"><p>Blowitoutahere</p></Tooltip>',
    name: "supplier_pricing.blowit",
    className: ".st",
    data: "supplier_pricing.blowit",
    title: `<img class='small-icon' title = "Blowitoutahere price" src=${require("../../../assets/icons/blowitoutahere.png")} alt="Blowitoutahere" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "CC Video supplier scraped price" position = "bottom" trigger = "click"><p>CCVideo</p></Tooltip>',
    name: "supplier_pricing.ccvideo",
    className: ".st",
    data: "supplier_pricing.ccvideo",
    title: `<img class='small-icon' title = "CCVideo price" src=${require("../../../assets/icons/ccvideo.png")} alt="CCVideo" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Chapters supplier scraped price" position = "bottom" trigger = "click"><p>Chapters</p></Tooltip>',
    name: "supplier_pricing.chapters",
    className: ".st",
    data: "supplier_pricing.chapters",
    title: `<img class='small-icon' title = "Chapters Indigo price" src=${require("../../../assets/icons/chapters.png")} alt="Chapters Indigo" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Deals Distributor Price" position = "bottom" trigger = "click"><p>Dealsareus</p></Tooltip>',
    name: "supplier_pricing.dealsareus",
    className: ".st",
    data: "supplier_pricing.dealsareus",
    title: `<img class='small-icon' title = "Deals R Us price" src=${require("../../../assets/icons/dealsareus.png")} alt="Deals R Us" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Deep Discount supplier scraped price" position = "bottom" trigger = "click"><p>DeepDiscount</p></Tooltip>',
    name: "supplier_pricing.deepdiscount",
    className: ".st",
    data: "supplier_pricing.deepdiscount",
    title: `<img class='small-icon' title = "Deep Discount price" src=${require("../../../assets/icons/deepdiscount.png")} alt="Deepdiscount" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Discount Entertainment supplier scraped price" position = "bottom" trigger = "click"><p>Discount Entertainment</p></Tooltip>',
    name: "supplier_pricing.discount_entertainment",
    className: ".st",
    data: "supplier_pricing.discount_entertainment",
    title: `<img class='small-icon' title = "Discount Entertainment price" src=${require("../../../assets/icons/discount_entertainment.png")} alt="Discount Entertainment" />`,
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Discount Entertainment price\n\nUpdated at: ${renderDate(
        row["product"]["discount_entertainment_data_timestamp"]
      )}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title = "Ebay BlowItOutofHere (Ebay Supplier) scraped price" position = "bottom" trigger = "click"><p>EbayBlowIt</p></Tooltip>',
    name: "supplier_pricing.blowitebay",
    className: ".st",
    data: "supplier_pricing.blowitebay",
    title: `<img class='small-icon' title = "Ebay Blowitoutahere price" src=${require("../../../assets/icons/ebayblowit.png")} alt="Ebay Blowit" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Ebay Low (Lowest available price of any seller) supplier scraped price" position = "bottom" trigger = "click"><p>EbayLowest</p></Tooltip>',
    name: "supplier_pricing.ebaylowest",
    className: ".st",
    data: "supplier_pricing.ebaylowest",
    title: `<img class='small-icon' title = "Ebay Low price" src=${require("../../../assets/icons/elow.png")} alt="Ebay Low" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Ebay V1 (Lowest price for a vetted seller) supplier scraped price" position = "bottom" trigger = "click"><p>EbayV1</p></Tooltip>',
    name: "supplier_pricing.ebayv1",
    className: ".st",
    data: "supplier_pricing.ebayv1",
    title: `<img class='small-icon' title = "Ebay Vetted1 price" src=${require("../../../assets/icons/ebayv1.png")} alt="Ebay Vetted1" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Ebay V2 (Second Lowest price for a vetted seller) supplier scraped price" position = "bottom" trigger = "click"><p>EbayV2</p></Tooltip>',
    name: "supplier_pricing.ebayv2",
    className: ".st",
    data: "supplier_pricing.ebayv2",
    title: `<img class='small-icon' title = "Ebay Vetted2 price" src=${require("../../../assets/icons/ebayv2.png")} alt="Ebay Vetted2" />`,
    excludeFromGeneralSearch: true,
  },

  {
    label:
      '<Tooltip title = "InetVideo Music scraped price (Website / Supplier has ceased operations)" position = "bottom" trigger = "click"><p>HeartLand</p></Tooltip>',
    name: "supplier_pricing.inetvideo",
    className: ".st",
    data: "supplier_pricing.inetvideo",
    title: `<img class='small-icon' title = "InetVideo price" src=${require("../../../assets/icons/inetvideo.png")} alt="InetVideo" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Import CDs supplier scraped price" position = "bottom" trigger = "click"><p>ImportCDs</p></Tooltip>',
    name: "supplier_pricing.importcds",
    className: ".st",
    data: "supplier_pricing.importcds",
    title: `<img class='small-icon' title = "ImportCDs price" src=${require("../../../assets/icons/importcds.png")} alt="ImportCDs" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Cutout supplier scraped price (Website / Supplier has ceased operations)" position = "bottom" trigger = "click"><p>Cutout</p></Tooltip>',
    name: "supplier_pricing.cutout",
    className: ".st",
    data: "supplier_pricing.cutout",
    title: `<img class='small-icon' title = "Cutout price" src=${require("../../../assets/icons/cutout.png")} alt="Cutout" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Mila supplier scraped price " position = "bottom" trigger = "click"><p>Mila</p></Tooltip>',
    name: "supplier_pricing.mila",
    className: ".st",
    data: "supplier_pricing.mila",
    title: `<img class='small-icon' title = "Mila price" src=${require("../../../assets/icons/mila.png")} alt="Mila" />`,
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Mila price\n\nUpdated at: ${renderDate(
        row["product"]["mila_data_timestamp"]
      )}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title = "Gruv supplier scraped price (Website / Supplier has ceased operations)" position = "bottom" trigger = "click"><p>Gruv</p></Tooltip>',
    name: "supplier_pricing.gruv",
    className: ".st",
    data: "supplier_pricing.gruv",
    title: `<img class='small-icon' title = "Gruv price" src=${require("../../../assets/icons/gruv.png")} alt="Gruv" />`,
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Gruv price\n\nUpdated at: ${renderDate(
        row["product"]["gruv_data_timestamp"]
      )}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title = "Wildcard1 product price" position = "bottom" trigger = "click"><p>Wildcard1</p></Tooltip>',
    name: "supplier_pricing.wildcard1",
    className: ".st",
    data: "supplier_pricing.wildcard1",
    title: `<img class='small-icon' title = "Wildcard 1 Price" src=${require("../../../assets/icons/wildcard1.png")} alt="WC1 Price" />`,
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Wildcard1 price\n\nUpdated at: ${renderDate(
        row["product"]["wildcard1_data_timestamp"]
      )}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title = "Wildcard2 product price" position = "bottom" trigger = "click"><p>Wildcard2</p></Tooltip>',
    name: "supplier_pricing.wildcard2",
    className: ".st",
    data: "supplier_pricing.wildcard2",
    title: `<img class='small-icon' title = "Wildcard 2 Price" src=${require("../../../assets/icons/wildcard2.png")} alt="WC2 Price" />`,
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Wildcard2 price\n\nUpdated at: ${renderDate(
        row["product"]["wildcard2_data_timestamp"]
      )}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title = "Rarewaves supplier scraped price" position = "bottom" trigger = "click"><p>Rarewaves</p></Tooltip>',
    name: "supplier_pricing.rarewaves",
    className: ".st",
    data: "supplier_pricing.rarewaves",
    title: `<img class='small-icon' title = "Rarewaves price" src=${require("../../../assets/icons/rarewaves.png")} alt="Rarewaves" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Target supplier scraped price (Website / Supplier has ceased operations)" position = "bottom" trigger = "click"><p>Target</p></Tooltip>',
    name: "supplier_pricing.target_store",
    className: ".st",
    data: "supplier_pricing.target_store",
    title: `<img class='small-icon' title = "Target price" src=${require("../../../assets/icons/target.png")} alt="Target" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "VEI scraped price  (Website / Supplier has ceased operations)" position = "bottom" trigger = "click"><p>VEI</p></Tooltip>',
    name: "supplier_pricing.vei",
    className: ".st",
    data: "supplier_pricing.vei",
    title: `<img class='small-icon' title = "VEI price" src=${require("../../../assets/icons/vei.png")} alt="VEI" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Rightstuf supplier scraped price" position = "bottom" trigger = "click"><p>RightStuff</p></Tooltip>',
    name: "supplier_pricing.rightstuf",
    className: ".st",
    data: "supplier_pricing.rightstuf",
    title: `<img class='small-icon' title = "Rightstuf price" src=${require("../../../assets/icons/rightstuf.png")} alt="Rightstuf" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Walmart Supplier Scraped Price" position = "bottom" trigger = "click"><p>Walmart</p></Tooltip>',
    name: "supplier_pricing.walmart",
    className: ".st",
    data: "supplier_pricing.walmart",
    title: `<img class='small-icon' title = "Walmart price" src=${require("../../../assets/icons/walmart.png")} alt="Walmart" />`,
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Walmart price\n\nUpdated at: ${renderDate(
        row["supplier_pricing"]["walmart_updated_timestamp"]
      )}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title = "BullMoose Supplier Scraped Price" position = "bottom" trigger = "click"><p>BullMoose</p></Tooltip>',
    name: "supplier_pricing.bullmoose",
    className: ".st",
    data: "supplier_pricing.bullmoose",
    title: `<img class='small-icon' title = "BullMoose price" src=${require("../../../assets/icons/bullmoose.png")} alt="BullMoose" />`,

    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "WowHD Supplier Scraped Price" position = "bottom" trigger = "click"><p>WOWHD</p></Tooltip>',
    name: "supplier_pricing.wowhd",
    className: ".st",
    data: "supplier_pricing.wowhd",
    title: `<img class='small-icon' title = "WowHD price" src=${require("../../../assets/icons/wowhd.png")} alt="WowHD" />`,

    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "PBSstore Supplier Scraped Price" position = "bottom" trigger = "click"><p>PBSstore</p></Tooltip>',
    name: "supplier_pricing.pbs_store",
    className: ".st",
    data: "supplier_pricing.pbs_store",
    title: `<img class='small-icon' title = "PBSstore price" src=${require("../../../assets/icons/pbs_store.png")} alt="PBSstore" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "UA1 Supplier Scraped Price" position = "bottom" trigger = "click"><p>UA1</p></Tooltip>',
    name: "supplier_pricing.ua1",
    className: ".st",
    data: "supplier_pricing.ua1",
    title: `<img class='small-icon' title = "UA1 price" src=${require("../../../assets/icons/ua1.png")} alt="UA1" />`,

    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Column used to sort collections within member of view" position = "bottom" trigger = "click"><p>Sort</p></Tooltip>',
    name: "supplier_pricing.sort",
    className: ".st",
    data: "supplier_pricing.sort",
    title:
      '<Tooltip title = "Column used to sort collections within member of view" position = "bottom" trigger = "click"><p>Sort</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
];

/**
 * Returns array of all columns for a given column set.
 * Will use the masterColumnSet if no column names are given.
 * @param {array} columnNames Optional
 * @returns {array}
 */
export function getColumnSet(columnNames) {
  if (!columnNames) return masterColumnSet;

  const columns = [];

  columnNames.forEach((col) => {
    const column = masterColumnSet.find(
      (colDefinition) => colDefinition.data === col
    );
    if (column) columns.push(column);
  });

  const columnsToHide = masterColumnSet
    .map((col) => col.name)
    .filter((name) => !columnNames.includes(name));

  // adding rest of columns from master set as hidden - edits will be broken otherwise
  const nonViewableColumns = masterColumnSet
    .filter((col) => columnsToHide.includes(col.data))
    .map((col) => ({ ...col, visible: false, type: "hidden" }));

  return [...columns, ...nonViewableColumns];
}

/**
 * Returns array of only *viewable* columns for a given column set.
 * Will use the masterColumnSet if no column names are given.
 * @param {array} columnNames Optional
 * @returns {array}
 */
export function getViewableColumns(columnNames) {
  let columnSet = [];
  if (!columnNames) {
    columnSet = masterColumnSet.map((c) => c);
  } else {
    columnSet = getColumnSet(columnNames);
  }

  // Adding column dependencies
  columnSet
    .map((c) => c)
    .forEach((col) => {
      if (col.columnDependencies) {
        col.columnDependencies.forEach((name) => {
          if (!columnSet.find((c) => c.data === name))
            columnSet.push({
              data: name,
              type: "hidden",
              visible: false,
            });
        });
      }
    });

  return columnSet;
}
